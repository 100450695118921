
import { defineComponent } from "vue";
import { mapGetters, mapActions, mapState } from "vuex";
import SalesOrder from "@/types/salesorder";
import Card from "primevue/card";
import SalesDialog from "@/components/Sales/SalesDialog.vue";
import ProductionStage from "@/components/Manufacturing/ProductionStage.vue";
import NotificationCard from "@/components/Notification-Card.vue";

export default defineComponent({
  name: "Released",
  components: {
    ProductionStage,
    SalesDialog,
    NotificationCard,
    Card,

  },
  computed: {
    ...mapState(["shipControl"]),
    ...mapGetters({
      getOrders: "sales/getOrders",
      getRangeEnd: "sales/getRangeEnd",
      getFirstRow: "sales/getFirstRow",
    }),
  },
  created() {
    this.getShipControl();
  },  
  data() {
    return {
      items: [] as Array<SalesOrder>,
      selectedSalesID: null,
      selectedCustomer: null,
      selectedSale: null,
      selectedRow: null,
      order: false,
      orderRecordsBy: "",
      selectedSalesStatus: null,
      readOnly: true,
      sortOrder: 1,
      sortField: "",
      first: 0,
      rows: 10,
      page: 1,
      isLoadingSales: false,
      status: [
        { status: "New", initial: "N" },
        { status: "Back Order", initial: "B" },
        { status: "Closed", initial: "C" },
      ],
      showSalesDialog: false,
      salesOrder: null as unknown,
      isResultView: true as boolean,
      lastUpdated: Date.now(),
      filename:"SOH.WEB",
      productionStage:["R"],
      dynamicColumns: [
      { field: 'FORMULA_PART_VIEW', header: 'Formula Part#', input: true },
      { field: 'FORMULA_WO_NO', header: 'Formula WO#', input: true },
      { field: 'BATCH_SIZE', header: 'Batch Size', input: true , align: 'right'},
      { field: 'BATCH_CODE', header: 'Batch Code', input: true },
      { field: 'APPROVED_DATE', header: 'Approved Date', input: false, calendar: true},
      { field: 'SPECIAL_INSTRUCTIONS', header: 'Special Instr.', input: true },
      { field: 'PROD_DESC_VIEW', header: 'Product Desc', input: true },
      { field: 'UNIT_SIZE', header: 'Size', input: true },
      { field: 'COSWAY_PART', header: 'Cosway Part Nbr' , input: true},
      { field: 'FG_WO_NO', header: 'FG Work Order No.', input: true },
      { field: 'PO_NUMBER', header: 'Cust PO' , input: true},
      { field: 'QTY_SCHED', header: 'Qty Sched' , input: true},
      { field: 'ORIGINAL_ID_VIEW', header: 'Sales Order', input: true },
      { field: 'SCHEDULE_DATE', header: 'Schedule Date', input: false, calendar: true },      
      ]
    };
  },
  mounted() {
    this.first = this.getFirstRow;
    
  },
  methods: {
    ...mapActions({
      setFirstRow: "sales/setFirstRow",
      fetchOrdersRecords: "sales/fetchOrdersRecords",
      getShipControl: "shipControl/getShipControl"
    }),
    openSales(edit: boolean) {
      this.showSalesDialog = true;
      this.readOnly = edit;
    },
    fetchFilteredOrders(addOrder: boolean) {
      this.isLoadingSales = true;
      this.fetchOrdersRecords({
          custs: [this.selectedCustomer?.["cust_id"]],
          id: this.selectedSalesID,
          status: this.selectedSalesStatus,
          addOrder: addOrder,
          correls: "cust_name",
        })
        .then((resp) => {
          this.items = resp.data;
        })
        .catch(() => {
          this.isLoadingSales = false;
        }).finally(() => {
          this.isLoadingSales = false;
        });
    },
    isResultViewChange(event: boolean) {
      this.isResultView = event
    },
    isDataUpdated(event: number) {
      this.lastUpdated = event;
    },    
  },
});
